<template>
  <div class="my-6">
    <PetRecordSection />
    <NavMenuSecondary />
  </div>
</template>

<script>
import PetRecordSection from "../components/newpetrecord/PetRecordSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    PetRecordSection,
    NavMenuSecondary,
  },
  beforeDestroy() {
    // clear the store
    this.$store.commit("errorForm", "");
    this.$store.commit("error", "");
  },
};
</script>
