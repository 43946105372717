<template>
  <div class="my-6">
    <FerretSection :pet="this.$route.params.pet" />
    <NavMenuSecondary page="newpet" :data="this.$route.params.pet" />
  </div>
</template>

<script>
import FerretSection from "../components/petrecord/ferret/FerretSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    FerretSection,
    NavMenuSecondary,
  },
};
</script>