<template>
  <div class="flex justify-center items-center flex-col">
    <div
      v-if="errors || getError"
      class="my-8 flex flex-col justify-center items-center text-red"
    >
      <h5 v-if="this.errors.length != 0 || getError">
        {{ $t("global_error") }}
      </h5>
      <ul
        v-for="(error, i) in this.errors"
        :key="i"
        class="mt-2 font-proximaLight text-sm text-left list-disc"
      >
        <li>{{ error }}</li>
      </ul>
      <FormError :errors="getError" />
    </div>
    <div
      v-if="speciesComp == 5"
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-start items-start"
    >
      <InputForm
        label="Species*"
        placeholder="Enter pet's species"
        v-model="petData.customSpecies"
        classAdd="w-4/12 mr-2 flex flex-col justify-start items-start"
        :error="errors.customSpecies"
        :inputClass="classMethod(errors.customSpecies, true)"
      />
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-start items-start"
    >
      <InputForm
        :label="$t('global_name') + '*'"
        placeholder="Enter pet's name"
        v-model="petData.callName"
        classAdd="w-4/12 mr-2 flex flex-col justify-start items-start"
        :inputClass="classMethod(errors.callName, true)"
      />
    </div>
    <!-- pet gender & breeding status -->
    <div class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row items-center">
      <div class="w-full flex items-center justify-center">
        <InputRadio
          class="mt-4"
          :title="$t('global_sex') + '*'"
          :opt1="$t('global_female')"
          :opt2="$t('global_male')"
          @input="inputSexType"
        />
      </div>
      <div
        class="w-full flex items-center justify-center"
        v-if="speciesComp != 4"
      >
        <InputRadio
          class="mt-4"
          :title="$t('pet_record_breeding_status') + '*'"
          :opt1="$t('global_sterilized')"
          :opt2="$t('global_intact')"
          @input="inputBreedingStatus"
        />
      </div>
      <div class="w-full flex items-center justify-center" v-else></div>
    </div>
    <!-- pet gender & breeding status end -->

    <!-- bird type & certificate -->
    <div
      v-if="speciesComp == 4"
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row items-center"
    >
      <div class="w-full mr-4 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="origin">{{
          `${$t("global_type")}*`
        }}</label>

        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          :class="{
            'border border-red outline-red': errors.genus,
          }"
          v-model="petData.genus"
        >
          <option value="" disabled selected>Select your bird’s genus</option>
          <option v-for="item in genusBirdList" :value="item.id" :key="item.id">
            {{ item.genusName }}
          </option>
        </select>
      </div>
      <div class="w-full flex flex-col justify-start items-start">
        <InputRadio
          class="mt-4"
          :title="$t('pet_record_cites_certificate') + '*'"
          :opt1="$t('global_no')"
          :opt2="$t('global_yes')"
          @input="inputCitesCertificate"
        />
      </div>
    </div>
    <!-- bird type & certificate end -->

    <!-- DOB -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadio
          class="mt-4"
          title="Do you know your pet’s date of birth?* "
          opt1="Yes"
          opt2="No"
          @input="inputQuestionDOB"
        />
      </div>

      <div
        v-if="questionDOB2 && questionDOB"
        class="w-full mr-2 flex flex-col justify-start items-start"
      >
        <label class="text-sm font-proximaMedium" for="dateOfBirth">{{
          `${$t("global_date_of_birth")}**`
        }}</label>
        <date-picker
          type="date"
          format="DD/MM/YYYY"
          v-model="dateBirth"
          @change="onChange()"
          placeholder="Select date"
          class="w-full rounded-sm my-1 text-sm"
          :class="{
            'border border-red outline-red': errors.dateOfBirth && !questionDOB,
          }"
        ></date-picker>
      </div>
      <InputForm
        v-if="questionDOB2 && !questionDOB"
        :label="$t('global_date_of_birth') + '*'"
        placeholder="Enter approximate age in months"
        v-model="petData.approximateAgeInMonths"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :inputClass="classMethod(errors.approximateAgeInMonths, true)"
      />
      <InputForm
        v-if="!questionDOB2"
        :disabled="true"
        :label="$t('global_date_of_birth') + '*'"
        placeholder="Enter approximate age in months"
        v-model="petData.approximateAgeInMonths"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :inputClass="classMethod(errors.dateOfBirth, !questionDOB)"
      />
    </div>
    <!-- DOB end -->

    <!-- ID band number -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadio
          class="mt-4"
          title="Does your pet have permanent ID?*"
          opt1="Yes"
          opt2="No"
          @input="inputQuestionIdBand"
        />
      </div>

      <InputForm
        :disabled="!questionIdBand"
        :label="$t('pet_record_microchip_number_id_brand') + '**'"
        :placeholder="$t('pet_record_enter_microchip_number')"
        v-model="petData.microchipNo"
        classAdd="w-full mt-4 mr-2 flex flex-col justify-start items-start"
        :inputClass="classMethod(errors.microchipNo, !questionIdBand)"
      />
    </div>
    <!-- ID band number end -->

    <!-- country of origin -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadio
          class="mt-4"
          title="Is your pet imported?*"
          opt1="Yes"
          opt2="No"
          @input="inputQuestionCountryOrigin"
        />
      </div>

      <div
        v-if="questionCountryOrigin"
        class="w-full mr-2 flex flex-col justify-start items-start"
      >
        <label class="text-sm font-proximaMedium" for="origin">{{
          `${$t("global_country_of_origin")}**`
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          :class="{
            'border border-red outline-red':
              errors.CountryofOrigin && !questionCountryOrigin,
            'bg-greyishWhite': !questionCountryOrigin,
          }"
          v-model="petData.CountryofOrigin"
          :disabled="!questionCountryOrigin"
        >
          <option value="" disabled selected>
            {{ $t("global_select_country") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <InputForm
        v-else
        :disabled="true"
        :label="$t('global_country_of_origin') + '*'"
        :placeholder="$t('global_country_of_origin')"
        v-model="account.country.name"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :inputClass="classMethod(errors.CountryofOrigin, true)"
      />
    </div>
    <!-- country of origin end -->

    <!-- dog & cat question pure bred -->

    <div
      v-if="speciesComp == 1 || speciesComp == 2"
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadio
          class="mt-4"
          title="Is your pet purebred?*"
          opt1="Yes"
          opt2="No"
          @input="inputQuestionPureBred"
        />
      </div>

      <div
        v-if="questionPureBred"
        class="w-full mr-2 flex flex-col justify-start items-start"
      >
        <label class="text-sm font-proximaMedium" for="breed">{{
          `${$t("pet_record_breed")}**`
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          :class="{
            'bg-greyishWhite': !questionPureBred,
            'border border-red outline-red': errors.breed && !questionPureBred,
          }"
          :disabled="!questionPureBred"
          v-model="petData.breed"
        >
          <option value="" disabled selected>
            {{ $t("global_select_Select_pet_breed") }}
          </option>

          <option v-for="item in breedsList" :value="item.id" :key="item.id">
            {{ item.breedName }}
          </option>
        </select>
      </div>
      <InputForm
        v-else
        :disabled="true"
        :label="$t('pet_record_breed') + '**'"
        :placeholder="$t('pet_record_breed')"
        v-model="petData.breed"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :inputClass="classMethod(errors.breed, true)"
      />
    </div>
    <!-- dog & cat question pure bred -->

    <!-- pet papers -->
    <div
      v-if="speciesComp == 1 || speciesComp == 2"
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-start"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadio
          class="mt-4"
          title="Does your pet have pedigree papers?***"
          opt1="Yes"
          opt2="No"
          @input="inputQuestionPedPapers"
        />
      </div>

      <div
        class="w-full mt-4 md:mt-0 mr-2 flex flex-col justify-start items-start"
      >
        <InputForm
          :disabled="!questionPedPapers"
          :label="$t('pet_record_official_registered_name') + '***'"
          :placeholder="$t('pet_record_enter_name')"
          v-model="petData.officialRegisteredName"
          classAdd="w-full mr-2 flex flex-col justify-start items-start"
          :inputClass="classMethod(errors.officialRegisteredName, true)"
        />

        <InputForm
          :disabled="!questionPedPapers"
          :label="$t('pet_record_pedigree_registration_number') + '***'"
          :placeholder="$t('pet_record_enter_pet_registration_number')"
          v-model="petData.pedigreeNo"
          classAdd="w-full mt-4 mr-2 flex flex-col
        justify-start items-start"
          :inputClass="classMethod(errors.pedigreeNo, questionPedPapers)"
        />
        <div
          v-if="questionPedPapers"
          class="w-full mt-4 ml-0 flex flex-col justify-start items-start"
        >
          <label class="text-sm font-proximaMedium" for="origin">{{
            `${$t("pet_record_national_registry")}***`
          }}</label>
          <select
            class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
            :class="{
              'bg-greyishWhite': !questionPedPapers,
              'border border-red outline-red':
                errors.nationalRegistry && !questionPedPapers,
            }"
            :disabled="!questionPedPapers"
            v-model="petData.nationalRegistry"
          >
            <option value="" disabled selected>Select national club</option>
            <option
              v-for="item in nationalKennelClubsList"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <InputForm
          v-else
          :disabled="true"
          :label="$t('pet_record_national_registry') + '***'"
          :placeholder="$t('pet_record_national_registry')"
          v-model="petData.nationalRegistry"
          classAdd="w-full mr-2 flex flex-col justify-start items-start"
        />
      </div>
    </div>
    <!-- pet papers -->

    <!-- height & weight -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <InputForm
        :label="$t('pet_record_height')"
        placeholder="0.00 cm"
        v-model="petData.height"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.height"
      />
      <InputForm
        :label="$t('pet_record_weight')"
        placeholder="0.000 kg"
        v-model="petData.weight"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.weight"
      />
    </div>
    <!-- height & weight end -->

    <!-- working titles & show titles -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <InputForm
        :label="$t('pet_record_working_titles')"
        @update="changeWorkingTitle"
        placeholder="HWT, IGP 3, MACH etc."
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.workingTitle"
      />
      <InputForm
        :label="$t('pet_record_show_titles')"
        @update="changeShowTitle"
        placeholder="International beauty champion etc."
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.showTitle"
      />
    </div>

    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <InputForm
        label="Primary ID type (optional)"
        placeholder="e.g. Pet passport"
        v-model="petData.primaryOptionalIDType"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.primaryOptionalIDType"
      />

      <InputForm
        label="Primary ID (optional)"
        placeholder="Maximum 25 characters"
        v-model="petData.primaryOptionalID"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.primaryOptionalID"
      />
    </div>
    <!-- primary ID end -->

    <!-- secondary ID -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <InputForm
        :label="$t('pet_record_secondary_id_type')"
        placeholder="e.g. Pet database "
        v-model="petData.secondaryOptionalIDType"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.secondaryOptionalIDType"
      />
      <InputForm
        :label="$t('pet_record_secondary_id')"
        placeholder="Maximum 25 characters"
        v-model="petData.secondaryOptionalID"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :error="errors.secondaryOptionalID"
      />
    </div>

    <div
      class="my-8 w-12/12 md:w-6/12 lg:w-8/12 flex flex-wrap justify-center md:justify-between items-center"
    >
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
      <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />

      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
      <div class="filler mx-1"></div>
    </div>
    <NewpetDiagnoses
      :unverifiedHealthStatus="this.unverifiedHealthStatus"
      @changeDignostic="changeDignostic"
      @deleteDiag="deleteDiag"
    />
    <div
      class="mt-8 w-11/12 md:w-6/12 lg:w-4/12 text-left font-proximaMedium text-sm"
    >
      <p class="font-proximaLight">
        <span class="text-red">{{ $t("global_note") }}</span>
        {{ $t("pet_record_text") }}
      </p>
    </div>
    <div
      class="w-full md:w-4/12 mt-4 flex flex-col md:flex-row items-center md:items-start justify-between"
    >
      <InputImagePets class="mx-1 mt-4" @input="pushToDoc" />
      <InputRadio3v
        class="mt-4"
        title="Document Type"
        :opt1="$t('pet_record_official_pedegree')"
        :opt2="$t('pet_record_EU_pet_passport')"
        :opt3="$t('pet_record_vet_hospital_bill')"
        @input="inputVal"
      />
    </div>
    <div
      class="mt-0 w-full font-proximaMedium flex justify-center items-center"
    >
      <div
        class="w-11/12 md:w-6/12 lg:w-4/12 md:mt-4 flex flex-row justify-center items-center"
      >
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-red"
          textCl="text-red"
          paddingX="px-8"
          width="w-full md:w-10/12"
          @click.native="submitCancel"
        />
        <SubmitButton
          @click.native="submitNewPet"
          class="ml-1"
          :title="$t('account_save_button')"
          background="bg-primary"
          paddingX="px-8"
          width="w-full md:w-10/12"
        />
      </div>
    </div>
  </div>
</template>
<!-- 
 formData.append("CITESCert", data.CITESCert ? 1 : 0); // 4
    formData.append("idBandNo", data.idBandNo); // 4
    formData.append("genus", data.genus); // 4 -->

<script>
import InputSelect from "../forms/InputSelect.vue";
import InputRadio from "../forms/InputRadio.vue";
import InputRadio3v from "../forms/InputRadio3v.vue";
import InputRadioEach from "../forms/InputRadioEach.vue";
import InputForm from "../forms/InputForm.vue";
import NewpetDiagnoses from "./NewpetDiagnoses.vue";
import InputImagePets from "../forms/InputImagePets.vue";
import DatePicker from "vue2-datepicker";
import FormError from "../warning/FormError.vue";
import "vue2-datepicker/index.css";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import moment from "moment";

import { getCountries } from "../../services/countriesServices";
import {
  breedsBySpecies,
  nationalKennelClubsBySpecies,
  genusBird,
} from "../../services/petRecordService";

import { mapActions, mapGetters } from "vuex";

export default {
  props: { file: {} },
  components: {
    InputSelect,
    InputRadio,
    InputRadioEach,
    InputRadio3v,
    InputForm,
    NewpetDiagnoses,
    InputImagePets,
    DatePicker,
    SubmitButton,
    BorderedButton,
    FormError,
  },
  data() {
    return {
      petData: {
        showTitle: [],
        workingTitle: [],
        customSpecies: "",
        callName: "",
        species: localStorage.getItem("species"), //1 dog - 2 cat - 3 ferret - 4 bird - 5 other
        CountryofOrigin: "",
        sex: true,
        CITESCert: "",
        dateOfBirth: null,
        approximateAgeInMonths: "",
        microchipNo: "",

        primaryOptionalID: "",
        primaryOptionalIDType: "",
        secondaryOptionalID: "",
        secondaryOptionalIDType: "",
        primaryIdType: "",
        primaryId: "",
        breed: "",
        purebredStatus: false,
        breedingStatus: true,
        pedigree: false,
        pedigreeNo: "",
        nationalRegistry: "",
        officialRegisteredName: "",
        weight: "0",
        height: "0",
        hasPedigreePapers: false,
        petIdentificationImage: null,
        petIdentificationImageType: 1,
        genus: "",
      },
      questionDOB: false,
      questionDOB2: false,
      questionIdBand: false,
      valQuestionIdBand: false,
      questionCountryOrigin: false,
      valQuestionCountryOrigin: false,
      questionPureBred: false,
      valQuestionPureBred: false,
      questionPedPapers: false,
      valQuestionPedPapers: false,
      dateBirth: null,
      userEmailAddress: localStorage.getItem("email"),
      vetEmailAddress: "technical@pawapeau.com",
      countriesList: [],
      breedsList: [],
      genusBirdList: [],
      nationalKennelClubsList: [],
      unverifiedHealthStatus: [],
      petGallery: [],
      error: false,
      errors: [],
    };
  },
  computed: {
    ...mapGetters({
      getError: "petRecord/getError",
      account: "auth/user",
    }),
    speciesComp() {
      return localStorage.getItem("species");
    },
  },
  async mounted() {
    const a = await getCountries();
    this.countriesList = a.data;
    const species = localStorage.getItem("species");
    if (species == 4) {
      const b = await genusBird();
      this.genusBirdList = b.data;
    }

    const req = {
      email: localStorage.getItem("email"),
      role: localStorage.getItem("role"),
    };

    this.getUser(req);

    if (species == 1 || species == 2) {
      const b = await breedsBySpecies(species);
      function compareBreeds(a, b) {
        if (a.breedName < b.breedName) return -1;
        if (a.breedName > b.breedName) return 1;
        return 0;
      }
      this.breedsList = b.data.sort(compareBreeds);

      const c = await nationalKennelClubsBySpecies(species);
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      this.nationalKennelClubsList = c.data.sort(compare);
    }
  },
  beforeDestroy() {
    // clear the store
    this.$store.commit("errorForm", "");
    this.$store.commit("error", "");
  },
  methods: {
    ...mapActions({
      addPetRecord: "petRecord/addPetRecord",
      getUser: "auth/getUser",
    }),
    inputQuestionIdBand(val) {
      this.valQuestionIdBand = true;
      this.questionIdBand = val == 1 ? true : false;
    },
    inputQuestionDOB(val) {
      this.questionDOB2 = true;
      this.questionDOB = val == 1 ? true : false;
    },
    inputQuestionPureBred(val) {
      this.valQuestionPureBred = true;
      this.questionPureBred = val == 1 ? true : false;
    },
    inputQuestionCountryOrigin(val) {
      this.valQuestionCountryOrigin = true;
      this.questionCountryOrigin = val == 1 ? true : false;
    },
    inputQuestionPedPapers(val) {
      this.valQuestionPedPapers = true;
      this.questionPedPapers = val == 1 ? true : false;
    },
    onChange() {
      this.petData.dateOfBirth = moment(String(this.dateBirth)).format(
        "DD/MM/YYYY"
      );
    },
    inputSexType(val) {
      this.petData.sex = val == 1 ? true : false; //0 for male / 1 for Female
    },
    inputCitesCertificate(val) {
      this.petData.CITESCert = val == 2 ? true : false;
    },
    inputVal(i) {
      console.log(i);
      this.petData.petIdentificationImageType = i;
    },
    inputpurebredStatus(val) {
      this.petData.purebredStatus = val == 2 ? true : false;
    },
    inputBreedingStatus(val) {
      this.petData.breedingStatus = val == 1 ? true : false;
    },
    inputPedigree(val) {
      this.petData.pedigree = val == 2 ? true : false;
    },
    nationalRegistryVal(val) {
      this.petData.nationalRegistry = val;
    },

    changeDignostic(val) {
      val.split(",").map((line) => {
        console.log(line);
        if (
          val[val.length - 1] == "," &&
          this.unverifiedHealthStatus.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.unverifiedHealthStatus.push(line);
        }
      });
    },
    changeWorkingTitle(val) {
      val.split(",").map((line) => {
        console.log(line);
        if (
          val[val.length - 1] == "," &&
          this.petData.workingTitle.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.petData.workingTitle.push(line);
        }
      });
    },

    changeShowTitle(val) {
      val.split(",").map((line) => {
        console.log(line);
        if (
          val[val.length - 1] == "," &&
          this.petData.showTitle.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.petData.showTitle.push(line);
        }
      });
    },

    deleteDiag(val) {
      const diags = this.unverifiedHealthStatus.filter((c) => c !== val);
      this.unverifiedHealthStatus = diags;
    },

    pushToGallery(val) {
      this.petGallery.push(val);
    },

    pushToDoc(val) {
      this.petData.petIdentificationImage = val;
    },

    validate() {
      const sp = localStorage.getItem("species");
      const errors = [];
      if (this.petData.callName.trim() === "")
        errors.callName = "Name is required";

      if (sp == 4) {
        if (this.petData.genus === "") {
          errors.genus = "Genus is required";
        }
      }
      if (sp == 5) {
        if (this.petData.customSpecies === "") {
          errors.customSpecies = "Species is required";
        }
      }
      if (!this.questionDOB) {
        if (sp != 4 && sp != 3 && sp != 5) {
          if (this.petData.dateOfBirth === null) {
            errors.dateOfBirth = this.$t("pet_record_error_1");

            errors.push(this.$t("pet_record_error_1"));
            errors.push(this.$t("pet_record_error_2"));
          }
        }
        if (sp == 4 || sp == 3) {
          if (this.petData.dateOfBirth === null) {
            errors.dateOfBirth = this.$t("pet_record_error_1");
            errors.push(this.$t("pet_record_error_1"));
            errors.push(this.$t("pet_record_error_2"));
          }
        }
      } else {
        if (
          this.petData.dateOfBirth === null &&
          this.petData.approximateAgeInMonths == ""
        )
          errors.approximateAgeInMonths =
            "Approximate age in months is required";
        else if (
          this.petData.dateOfBirth === null &&
          !Number(this.petData.approximateAgeInMonths)
        ) {
          errors.approximateAgeInMonths =
            "Approximate age in months must be a number";
          errors.push("Approximate age in months must be a number");
        }
      }

      if (this.valQuestionIdBand == false) {
        errors.microchipNo = "Microchip number is required";
      } else {
        if (this.questionIdBand) {
          if (this.petData.microchipNo.trim() === "")
            errors.microchipNo = "Microchip number is required";
          else {
            var re = /^\d{1,50}$/;
            if (!re.test(this.petData.microchipNo)) {
              errors.microchipNo = "A Microchip may only consist of numbers.";

              errors.push("A Microchip may only consist of numbers.");
            } else {
              if (this.petData.microchipNo > 999999999999999999999999) {
                errors.microchipNo =
                  "A Microchip must contain less than 25 digits.";
                errors.push("A Microchip must contain less than 25 digits.");
              }
            }
          }
        }
      }
      if (this.valQuestionCountryOrigin == false) {
        errors.CountryofOrigin = "Country of Origin is required";
      } else {
        if (this.questionCountryOrigin && this.petData.CountryofOrigin == "")
          errors.CountryofOrigin = "Country of Origin is required";
      }
      if (sp != 4 && sp != 3 && sp != 5) {
        if (this.valQuestionPureBred == false) {
          errors.breed = "Breed is required";
        } else {
          if (this.questionPureBred && this.petData.breed == "")
            errors.breed = "Breed is required";
        }
      }
      if (sp != 4 && sp != 3 && sp != 5) {
        if (this.valQuestionPedPapers == false) {
          errors.officialRegisteredName =
            "Official registered name is required";
        } else {
          if (
            this.questionPedPapers &&
            this.petData.officialRegisteredName.trim() === ""
          )
            errors.officialRegisteredName =
              "Official registered name is required";
          if (this.questionPedPapers && this.petData.pedigreeNo.trim() === "")
            errors.pedigreeNo = "Pedigree Registration Number is required";
          if (
            this.questionPedPapers &&
            this.petData.nationalRegistry.trim() === ""
          )
            errors.nationalRegistry = "National Registry is required";
        }
      }
      /*  if (this.petData.petIdentificationImage == null)
        errors.push("A pet’s identity must be added"); */

      return Object.keys(errors).length === 0 ? null : errors;
    },

    async submitNewPet() {
      if (this.petData.dateOfBirth === null) {
        this.errorData = true;
      }
      const errors = this.validate();
      this.errors = errors || [];
      if (errors) {
        console.log(errors);
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      const data = {
        petData: this.petData,
        userEmailAddress: this.userEmailAddress,
        vetEmailAddress: this.vetEmailAddress,
        unverifiedHealthStatus: this.unverifiedHealthStatus,
        petProfileImg: this.file ? this.file : null,
        petGallery: this.petGallery,
      };

      this.addPetRecord(data);
    },
    submitCancel() {
      localStorage.getItem("role") == 4 || localStorage.getItem("role") == 5 //sp + vet
        ? this.$router.push("/sppassivedashboard/new")
        : this.$router.push("/petoverview/news");
    },
    classMethod(error, bool) {
      return error && bool ? "border border-red" : "border-0";
    },
  },
};
</script>

<style>
.filler {
  width: 180px;
  height: 0px;
}

.mx-datepicker {
  width: 100%;
}

.mx-datepicker > div > input {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.mx-input {
  border: none;
  height: auto;
}
</style>
